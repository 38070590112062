import React from 'react';
import style from './footer.module.scss';
import { PagePath } from '@/models/enums';

export const Footer = () => (
  <footer className={style.footer}>
    <div className={style.footer__logo}>
      <p className="small">
        <span className="inner">Experience the Power of Innovation</span>
      </p>
      <a href="/">
        <img
          width="213"
          height="50"
          src={`${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/logo-footer.svg`}
          alt="devPulse"
        />
      </a>
    </div>
    <div className={style.clutch}>
      <div className={style.clutch__inner}>
        <a href="https://clutch.co/profile/devpulse#highlights" target="_blank" rel="nofollow">
          <img
            width="140"
            height="151"
            src="https://shareables-prod-static.clutch.co/badges/top_clutch.co_web_design_company_medical_kharkiv.svg"
            alt="Top Clutch.co Web Design Company Medical Kharkiv"
          />
        </a>
      </div>
    </div>
    <p className={`${style.privacy__policy} extra-small`}>
      <span className="inner">
        <a href={PagePath.PrivacyPolicy} target="_blank">
          Privacy Policy
        </a>
      </span>
    </p>
    <p className="extra-small">
      <span className="inner">{`Copyright © ${new Date().getFullYear()} devPulse. All rights reserved.`}</span>
    </p>
  </footer>
);
