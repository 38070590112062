import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Link, scroller } from 'react-scroll';
import './header.scss';
import { NavMenuId, PagePath } from '@/models/enums';
import { LinkButton } from '@/components/LinkButton/LinkButton';

interface HeaderPropsInterface {
  isBannerScrolled: boolean;
}

interface NavMenuItemInterface {
  title: string;
  id: NavMenuId;
}

const navMenu: NavMenuItemInterface[] = [
  { title: 'Who we are', id: NavMenuId.HoWeAre },
  { title: 'Why us', id: NavMenuId.Us },
  { title: 'Services', id: NavMenuId.Services },
  { title: 'Portfolio', id: NavMenuId.Portfolio },
  { title: 'How we work', id: NavMenuId.HowWeWork },
  { title: 'Our team', id: NavMenuId.OurTeam },
  { title: 'Blog', id: NavMenuId.Blog },
  { title: 'Contacts', id: NavMenuId.Contacts },
];

export const Header = ({ isBannerScrolled }: HeaderPropsInterface) => {
  const [isMenuListDropped, setIsMenuListDropped] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleHamburgerMenuClick = () => {
    setIsMenuListDropped(!isMenuListDropped);
  };

  const scrollTo = (id: string) => {
    setTimeout(() => {
      scroller.scrollTo(id, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }, 500);
  };

  const goToPageAndScroll = (page: string, target: string) => {
    navigate(page);
    scrollTo(target);
  };

  const renderNavMenu = (menuItem: NavMenuItemInterface) => {
    const location = useLocation().pathname;
    if (location === PagePath.Home) {
      return (
        <Link
          to={menuItem.id}
          href="#"
          spy={true}
          smooth={true}
          duration={600}
          offset={-80}
          activeClass="active"
        >
          <span className="inner">{menuItem.title}</span>
        </Link>
      );
    } else {
      return (
        <RouterLink
          to={PagePath.Home}
          onClick={() => goToPageAndScroll(PagePath.Home, menuItem.id)}
        >
          <span className="inner">{menuItem.title}</span>
        </RouterLink>
      );
    }
  };

  const getAnchorParamFromURL = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get('anchor');
  };

  const scrollHandler = () => {
    const id = getAnchorParamFromURL();
    if (id !== null && Object.values(NavMenuId).includes(id as NavMenuId)) {
      scrollTo(id as NavMenuId);
    }
  };

  useEffect(() => {
    scrollHandler();
  }, []);

  return (
    <header className={`header ${isBannerScrolled ? 'header-top' : ''}`} id="header">
      <nav className="nav-menu">
        <a className="nav-logo logo-name" href={PagePath.Home}>
          <img
            width="164"
            height="28"
            className="img"
            src={`${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/logo-name.svg`}
            alt="devPulse"
          />
        </a>
        <a className="nav-logo logo-icon" href={PagePath.Home}>
          <img
            width="171"
            height="40"
            className="img"
            src={`${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/logo-icon.svg`}
            alt="devPulse"
          />
        </a>
        <div className="nav-mobile" onClick={handleHamburgerMenuClick}>
          <div className={`nav-hamburger ${isMenuListDropped ? 'dropped' : ''}`}>
            <span></span>
          </div>
        </div>
        <ul className={`nav-list ${isMenuListDropped ? 'dropped' : ''}`}>
          {navMenu.map((menuItem, index) => {
            const droppedClass = isMenuListDropped ? 'dropped' : '';
            const itemClass = `nav-item nav-item--${index} ${droppedClass}`;
            return (
              <li className={itemClass} key={menuItem.id}>
                {menuItem.id !== NavMenuId.Blog && renderNavMenu(menuItem)}
                {menuItem.id === NavMenuId.Blog && (
                  <a href="/blog">
                    <span className="inner">Blog</span>
                  </a>
                )}
              </li>
            );
          })}
        </ul>
        <div className="nav-btn">
          <LinkButton title="Let`s talk" link={NavMenuId.Contacts} className="btn-header" />
        </div>
      </nav>
    </header>
  );
};
