import React from 'react';
import { NavMenuId } from '@/models/enums';
import style from './services.module.scss';
import { LinkButton } from '@/components/LinkButton';
import { ReturnHTMLParagraph } from '@/components/ReturnHTMLParagraph';

interface ServiceInterface {
  id: string;
  icon: string;
  title: string;
  desc: string[];
  industries: string;
  areas: string[];
}

const services: ServiceInterface[] = [
  {
    id: 'customSoftware',
    icon: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/services/custom-software.svg`,
    title: 'Custom software development',
    desc: [
      'Developing software products is what <span className="bold">we’ve done for over 20 years</span>. If you value true expertise in custom-made software delivery, you’ve come to the right place.',
      'With experienced business and technology consultants you can transform your ways of working with ground-breaking technology, <span className="bold">optimize the costs and processes and let your business benefit from innovation</span>.',
    ],
    industries: 'AgroTech / Real Estate / Collaboration & Productivity',
    areas: ['Windows', 'Mac', 'Cloud', 'Mobile', 'ML', 'Web', 'loT'],
  },
  {
    id: 'webDevelopment',
    icon: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/services/web-development.svg`,
    title: 'Web Development',
    desc: [
      'Our experienced and knowledgeable web developers focus on creating websites that are not only visually attractive, but also <span className="bold">highly functional and easy to use</span>.',
    ],
    industries: 'E-commerce / Landing pages / Corporate websites & portals',
    areas: ['Node.js', 'PHP', 'AWS', 'React', 'Vue', 'DigitalOcean', 'WordPress'],
  },
  {
    id: 'desktopDevelopment',
    icon: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/services/desktop-development.svg`,
    title: 'Desktop Development',
    desc: [
      'DevPulse has a proven track record of <span className="bold">developing complex desktop applications</span> with features ranging from system-level integration to user-oriented graphical interfaces for Windows, Linux, and MacOS platforms.',
    ],
    industries: 'Graphics / CAD',
    areas: [
      'WPF',
      'Windows',
      'Mac',
      'COM',
      'Swift',
      'Cocoa',
      'WebAssembly',
      'Electron',
      'Windows Form',
      '.Net',
      'ObjectiveC',
      'C++',
      'ReactNative',
    ],
  },
  {
    id: 'mobileDevelopment',
    icon: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/services/mobile-development.svg`,
    title: 'Mobile Development',
    desc: [
      'We offer <span className="bold">enterprise-grade and full-cycle mobile app development</span> tailored to your needs. We have a proven track record of delivering successful mobile app projects for both large enterprises and StartUps. Our developers understand that the best mobile apps are those that match the needs of the market, and that’s why we put our customer’s goals first. Our experienced developers are passionate about building apps that are <span className="bold">easy to use, visually appealing, and drive business growth</span>.',
    ],
    industries: 'Collaboration / Networking / Productivity',
    areas: ['Node.js', 'PHP', 'AWS', 'WordPress', 'React', 'Vue', 'DigitalOcean'],
  },
];

export const Services = () => {
  return (
    <section id={NavMenuId.Services}>
      <div className={style.background__1}></div>
      <div className={style.background__2}></div>
      <div className={style.background__3}></div>
      <div className={style.background__4}></div>
      <div className={style.section}>
        <h2 className={style.title}>
          <span className="inner">
            Our <span className="color__orange">services</span>
          </span>
        </h2>
        <div className={style.content}>
          <div className={style.row}>
            <div className={style.services}>
              <ul className={style.services__list}>
                {services.map((service) => (
                  <li key={service.id} className={style.service__item}>
                    <div className={style.service}>
                      <div className={style.service__icon}>
                        <img width="24" height="24" src={service.icon} alt={service.id} />
                      </div>
                      <div className={style.service__content}>
                        <div className={style.service__part}>
                          <div className={style.service__desc}>
                            <h3>
                              <span className="inner">{service.title}</span>
                            </h3>
                            {service.desc.map((paragraph, key) => (
                              <div key={`${service.id}_${key}`}>
                                <ReturnHTMLParagraph htmlString={paragraph} />
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className={style.service__part}>
                          <div className={style.service__industries}>
                            <p className="bold">
                              <span className="inner">Industries:</span>
                            </p>
                            <p className="small">
                              <span className="inner">{service.industries}</span>
                            </p>
                          </div>
                          <div className={style.service__areas}>
                            <p className="bold">
                              <span className="inner">TechStack:</span>
                            </p>
                            {service.areas.map((area, key) => (
                              <div key={`${service.id}_${key}`} className={style.service__area}>
                                <p className="bold small">
                                  <span className="inner">{area}</span>
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <div className={style.services__offer}>
                <h2 className={style.services__title}>
                  <span className="inner">
                    <span>
                      High-quality services for the unique needs of our&nbsp;
                      <span className={style.highlighted}>
                        client
                        <img
                          width="127"
                          height="59"
                          src={`${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/highlighted-word.svg`}
                          alt="highlighted"
                        />
                      </span>
                    </span>
                  </span>
                </h2>
                <div className={style.services__desc}>
                  <p className="small">
                    <span className="inner">
                      When you choose our services, you choose partnering with a reliable,
                      trustworthy, and innovative team that is committed to your success.
                    </span>
                  </p>
                  <div className={style.services__btn}>
                    <LinkButton title="Contact us" link={NavMenuId.Contacts} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row row--2"></div>
        </div>
      </div>
    </section>
  );
};
