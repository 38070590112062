import React, { useEffect, FC } from 'react';

interface SliderPropsInterface {
  currentSlideIndex: number;
  onToggleSlider: (slideIndex: number) => void;
}

const sliderImages = [
  `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/media/slider/image1.webp`,
  `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/media/slider/image2.webp`,
  `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/media/slider/image3.webp`,
];

export const Slider: FC<SliderPropsInterface> = ({ currentSlideIndex, onToggleSlider }) => {
  const goToSlide = (slideIndex: number) => {
    onToggleSlider(slideIndex);
  };

  const goToNextSlide = () => {
    // The result will be currentSlide + 1, and when (currentSlide + 1 === numberOfSlides) the result will be 0
    const newIndex = (currentSlideIndex + 1) % sliderImages.length;
    goToSlide(newIndex);
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      goToNextSlide();
    }, 3000);

    return () => {
      clearTimeout(timerId); // Clear the timer when the component unmounts
    };
  }, [currentSlideIndex]);

  return (
    <div className="slider">
      {sliderImages.map((image, index) => (
        <img
          width="auto"
          height="auto"
          key={index}
          className={`slider-image ${currentSlideIndex === index ? 'visible' : 'hidden'}`}
          src={image}
          alt={`Slide ${index}`}
          onClick={() => goToNextSlide()}
        />
      ))}
      <div className="pagination">
        {sliderImages.map((image, index) => (
          <div
            key={image}
            className={`pagination-dot ${currentSlideIndex === index ? 'active' : ''}`}
          ></div>
        ))}
      </div>
    </div>
  );
};
