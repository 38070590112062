import React from 'react';
import style from './howwework.module.scss';
import { NavMenuId } from '@/models/enums';

interface WorkstepItemInterface {
  id: string;
  arrow?: string;
  stepNumber: string;
  title: string;
  desc: string;
}

const workSteps: WorkstepItemInterface[] = [
  {
    id: 'discussion',
    arrow: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/arrow-short.svg`,
    stepNumber: '01.',
    title: 'Discussion',
    desc: 'In this initial phase, stakeholders gather to exchange ideas, share perspectives, and define the project`s scope and objectives. It`s a collaborative stage where key considerations are identified and initial plans are laid out.',
  },
  {
    id: 'concept',
    arrow: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/arrow-short.svg`,
    stepNumber: '02.',
    title: 'Concept',
    desc: 'Building on the discussions, a clear concept is formulated. This includes outlining the project`s goals, target audience, features, and potential challenges. The concept serves as a foundation for the subsequent stages.',
  },
  {
    id: 'strategy',
    stepNumber: '03.',
    title: 'Strategy',
    desc: 'Once the concept is solidified, a strategic plan is developed. This involves mapping out the detailed steps, resource allocation, timelines, and risk management strategies necessary to bring the concept to fruition.',
  },
  {
    id: 'implementation',
    arrow: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/arrow-long.svg`,
    stepNumber: '04.',
    title: 'Implementation',
    desc: 'The strategy transitions into action during this stage. Developers, designers, and other relevant experts work together to create the project according to the established plan. This stage focuses on turning concepts into tangible products or solutions.',
  },
  {
    id: 'testing',
    stepNumber: '05.',
    title: 'Testing & Evaluation',
    desc: 'Rigorous testing is conducted to identify and rectify any issues or discrepancies. The project is evaluated against predefined criteria to ensure it aligns with the initial concept and meets quality standards.',
  },
  {
    id: 'support',
    arrow: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/arrow-short.svg`,
    stepNumber: '06.',
    title: 'Support & Updates',
    desc: 'After deployment, ongoing support is provided to address user concerns, fix bugs, and offer assistance. Additionally, updates and enhancements are periodically released to keep the project current and responsive to evolving needs.',
  },
];

export const Howwework = () => (
  <section id={NavMenuId.HowWeWork} className={style.howwework}>
    <div className={`${style.section} section`}>
      <div className={`${style.content} content`}>
        <div className={style.worksteps__wrapper}>
          <h2 className={style.worksteps__title}>
            <span className="inner">
              How we <span className="color__orange">work</span>
            </span>
          </h2>
          <ul className={style.worksteps}>
            {workSteps.map((workStep, index) => (
              <li
                key={workStep.id}
                className={`${style.workstep} ${style['workstep' + '--' + index]}`}
              >
                {workStep.arrow && (
                  <img
                    width="155"
                    height="155"
                    src={workStep.arrow}
                    alt="arrow"
                    className={style.workstep__arrow}
                  />
                )}
                <div className={style.workstep__number}>
                  <span>{workStep.stepNumber}</span>
                </div>
                <h3 className={style.workstep__title}>
                  <span className="inner">{workStep.title}</span>
                </h3>
                <p className={`${style.workstep__desc} extra-small`}>
                  <span className="inner">
                    <span>{workStep.desc}</span>
                  </span>
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </section>
);
