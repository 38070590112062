import React from 'react';
import { NavMenuId } from '@/models/enums';
import style from './portfolio.module.scss';

interface WorksInterface {
  id: string;
  iconLink: string;
  bgLink: string;
  title: string;
  desc: string;
  linkTo: string;
  teamSize: string;
  duration: string;
  platforms?: string[];
  techStack?: string[];
}

const works: WorksInterface[] = [
  {
    id: 'workMaping',
    iconLink: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/media/portfolio/icon-maping.webp`,
    bgLink: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/media/portfolio/bg-maping.webp`,
    title: 'Mind Mapping solution',
    desc: 'The solution is a versatile tool that helps individuals and teams capture, organize, and communicate ideas effectively, making it useful for brainstorming sessions, project planning, knowledge management, and more.',
    linkTo: 'https://www.mindmanager.com',
    teamSize: '30',
    duration: '5 years+',
    platforms: ['Windows', 'Mac', 'Web'],
  },
  {
    id: 'workPdf',
    iconLink: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/media/portfolio/icon-pdf.webp`,
    bgLink: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/media/portfolio/bg-pdf.webp`,
    title: 'PDF editing application',
    desc: 'The application is a software application that allows users to view, create, edit, convert, and secure PDF (Portable Document Format) files. ',
    linkTo: '#',
    teamSize: '10',
    duration: '18 month',
    techStack: ['Electron', 'Typescript', 'React'],
  },
  {
    id: 'warkAdsBlocking',
    iconLink: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/media/portfolio/icon-adsblocking.webp`,
    bgLink: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/media/portfolio/bg-adsblocking.webp`,
    title: 'Ads blocking solution',
    desc: 'The solution is designed to block advertisements (ads) displayed on websites.',
    linkTo: 'https://www.adremover.org',
    teamSize: '6',
    duration: '20 month+',
    techStack: ['Chrome browser', 'Firefox browser', 'Windows', 'Android', 'iOS', 'Web'],
  },
  {
    id: 'workEnterpris',
    iconLink: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/media/portfolio/icon-enterprise.webp`,
    bgLink: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/media/portfolio/bg-enterprise.webp`,
    title: 'Enterprise entitlement platform',
    desc: 'The software solution that enables customer to manage and enforce the licensing and entitlements of their software applications or digital products.',
    linkTo: '#',
    teamSize: '11',
    duration: '8 month+',
    platforms: ['Node.js', 'React', 'Next', 'AWS', 'K8s', 'Terraform', 'PostgreSQL'],
  },
  {
    id: 'workMigrationToWeb',
    iconLink: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/media/portfolio/icon-migrationtoweb.webp`,
    bgLink: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/media/portfolio/bg-migrationtoweb.webp`,
    title: 'Native Graphic designer migration to web',
    desc: 'The software solution that enables customer to manage and enforce the licensing and entitlements of their software applications or digital products.',
    linkTo: '#',
    teamSize: '3',
    duration: '16 month',
    techStack: ['WebAssembl', 'Emscripten', 'Windows API', 'Javascript'],
  },
];

export const Portfolio = () => (
  <section id={NavMenuId.Portfolio} className={style.portfolio}>
    <div className={style.background__1}></div>
    <div className={style.background__2}></div>
    <div className={style.background__3}></div>
    <div className={style.background__4}></div>
    <div className="section">
      <h2>
        <span className="inner">
          Our <span className="color__orange">Portfolio</span>
        </span>
      </h2>
      <div className="content">
        <div className={style.works}>
          <ul className={style.works__list}>
            {works.map((work) => (
              <li key={work.id} className={style.work__item}>
                <div className={style.work}>
                  <div className={style.work__bg}>
                    <img width="419" height="278" src={work.bgLink} alt={work.id} />
                  </div>
                  <div className={style.work__icon}>
                    <img
                      width="80"
                      height="80"
                      className={style[work.id]}
                      src={work.iconLink}
                      alt={work.id}
                    />
                  </div>
                  <div className={style.work__info}>
                    <h3 className={style.work__title}>
                      <span className="inner">{work.title}</span>
                    </h3>
                    <p className={style.work__desc}>
                      <span className="inner">{work.desc}</span>
                    </p>
                    <div className={style.work__linkTo}>
                      <a href={work.linkTo} target="_blank" rel="noreferrer">
                        <span className="inner">
                          Read More <span>&#8594;</span>
                        </span>
                      </a>
                    </div>
                    <div className={style.work__params}>
                      <div>
                        <p className="bold">
                          <span className="inner">TeamSize:</span>
                        </p>
                        <p>
                          <span className="inner">{work.teamSize}</span>
                        </p>
                      </div>
                      <div>
                        <p className="bold">
                          <span className="inner">Duration:</span>
                        </p>
                        <p>
                          <span className="inner">{work.duration}</span>
                        </p>
                      </div>
                    </div>
                    <div className={style.work__platforms}>
                      {work.platforms && (
                        <p className="bold">
                          <span className="inner">Platforms:</span>
                        </p>
                      )}
                      {work.platforms &&
                        work.platforms.map((platform, key) => (
                          <div key={`${work.id}_${key}`} className={style.work__platform}>
                            <p className="small">
                              <span className="inner">{platform}</span>
                            </p>
                          </div>
                        ))}
                      {work.techStack && (
                        <p className="bold">
                          <span className="inner">TechStack:</span>
                        </p>
                      )}
                      {work.techStack &&
                        work.techStack.map((platform, key) => (
                          <div key={`${work.id}_${key}`} className={style.work__platform}>
                            <p className="small">
                              <span className="inner">{platform}</span>
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </section>
);
