import React from 'react';
import { Privacy } from '@/components/PrivacyPolicy';
import { Header } from '@/components/Header';
import { Footer } from '@/components/Footer';

export const PrivacyPolicy = () => (
  <div className="page">
    <Header isBannerScrolled={true} />
    <Privacy />
    <Footer />
  </div>
);
