export function serviceWorkerRegistration() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register(`${process.env.REACT_APP_ROOT_URL}/serviceWorker.js`)
        .then((registration) => {
          console.log('Service Worker registered with scope: ', registration.scope);
        })
        .catch((err) => {
          console.log('Failed to register Service Worker:', err);
        });
    });
  }
}
