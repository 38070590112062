import React from 'react';
import './contacts.scss';
import { NavMenuId, PagePath } from '@/models/enums';
import { FormSubmit } from '@/components/FormSubmit';

interface ContactInterface {
  id: string;
  icon: string;
  title: string;
  link: string;
}

const contactsData: ContactInterface[] = [
  {
    id: 'address',
    icon: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/icons/address.svg`,
    title: 'LLC DEVPULSE ul. Adama Branickiego 21/U3 Warsaw, Poland, 02-972',
    link: 'https://goo.gl/maps/Rup4G1TZFPTvenbj9',
  },
  {
    id: 'phone',
    icon: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/icons/phone.svg`,
    title: '+48 57 475 16 36',
    link: 'tel:+48574751636',
  },
  {
    id: 'in',
    icon: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/icons/in.svg`,
    title: 'Stay connected',
    link: 'https://www.linkedin.com/company/devpulse-io',
  },
  {
    id: 'mail',
    icon: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/icons/mail.svg`,
    title: 'sales@devpulse.io',
    link: 'mailto:sales@devpulse.io',
  },
  {
    id: 'clutch',
    icon: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/icons/clutch.svg`,
    title: 'Clutch',
    link: 'https://clutch.co/profile/devpulse',
  },
];

export const Contacts = () => (
  <section id={NavMenuId.Contacts} className="contacts">
    <div className="background__1"></div>
    <div className="background__2"></div>
    <div className="section">
      <div className="row">
        <div className="column">
          <div className="requisites">
            <h2>
              <span className="inner">
                <span>
                  Let’s&nbsp;
                  <span className="highlighted">
                    build
                    <img
                      width="127"
                      height="59"
                      src={`${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/highlighted-word.svg`}
                      alt="highlighted"
                    />
                  </span>
                  &nbsp;something amazing together
                </span>
              </span>
            </h2>
            <ul className="list">
              {contactsData.map((contact, index) => (
                <li key={contact.id} className={`item item--${index}`}>
                  <div className="icon">
                    <a href={contact.link} target="_blank">
                      <img width="24" height="24" src={contact.icon} alt={contact.id} />
                    </a>
                  </div>
                  <a href={contact.link} target="_blank">
                    <span className="inner">{contact.title}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="column">
          <div className="form__wrapper">
            <h2 className="title">
              {' '}
              <span className="inner">Drop us a line</span>
            </h2>
            <FormSubmit />
            <p className="policy extra-small">
              <span className="inner">
                I accept the <a href={PagePath.PrivacyPolicy}>Privacy Policy</a>.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);
