import React, { useEffect, useRef, useState } from 'react';
import style from '@/components/Banner/banner.module.scss';
import { Header } from '@/components/Header';
import { LinkButton } from '@/components/LinkButton';
import { NavMenuId } from '@/models/enums';

export const Banner = () => {
  /* ---- Start Scroll improving ---- */
  const [isBannerScrolled, setIsBannerScrolled] = useState(false);
  const bannerRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    if (bannerRef.current) {
      const bannerPosition = bannerRef.current.getBoundingClientRect().top;
      const bannerHeight = bannerRef.current.getBoundingClientRect().height;
      setIsBannerScrolled(bannerPosition < -bannerHeight + 40);
    }
  };
  const throttledScroll = () => {
    setTimeout(handleScroll, 100);
  };
  useEffect(() => {
    window.addEventListener('scroll', throttledScroll);
    return () => {
      window.removeEventListener('scroll', throttledScroll);
    };
  }, []);
  /* ---- End Scroll improving ---- */

  return (
    <div className={style.banner} id="banner" ref={bannerRef}>
      <section className={style.section}>
        <div className={style.container}>
          <div className={style.logo}>
            <img
              width="903"
              height="414"
              className={style.bg}
              src={`${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/hero-logo-bg.svg`}
              alt="technologies"
            />
            <div className={style.banner__icon}>
              <img
                src={`${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/hero-logo.svg`}
                alt="devPulse"
              />
            </div>
          </div>
          <div className={style.content}>
            <h1 className={style.title}>
              <span className="inner">
                <span className="color__orange">Your</span> global tech{' '}
                <span className="color__orange">partner</span> for software innovation
              </span>
            </h1>
            <p className={`${style.desc} h4`}>
              <span className="inner">
                 We create products that you need
              </span>
            </p>
            <LinkButton title="Let’s talk" link={NavMenuId.Contacts} />
          </div>
        </div>
      </section>
      <Header isBannerScrolled={isBannerScrolled} />
    </div>
  );
};
