import React from 'react';
import style from './ourteam.module.scss';
import { NavMenuId } from '@/models/enums';
import { ReturnHTMLParagraph } from '@/components/ReturnHTMLParagraph';

interface TeamMembersInterface {
  id: string;
  name: string;
  position: string;
  avatar: string;
}

const teamMembers: TeamMembersInterface[] = [
  {
    id: '1',
    name: 'Vladyslav Tukhtarov',
    position: 'Chief Executive<br/>Officer',
    avatar: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/media/team/vladyslav-tukhtarov.webp`,
  },
  {
    id: '2',
    name: 'Anna Tukhtarova',
    position: 'Chief Technology<br/>Officer',
    avatar: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/media/team/anna-tukhtarova.webp`,
  },
  {
    id: '3',
    name: 'Vitalii Starostin',
    position: 'Chief Quality<br/>Officer',
    avatar: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/media/team/vitalii-starostin.webp`,
  },
];

export const Ourteam = () => (
  <section id={NavMenuId.OurTeam} className={style.ourteam}>
    <div className={style.background__1}></div>
    <div className={style.background__2}></div>
    <div className={style.background__3}></div>
    <div className="section">
      <h2>
        <span className="inner">
          Our <span className="color__orange">team</span>
        </span>
      </h2>
      <div className={style.content}>
        <div className={style.team}>
          <ul className={style.members}>
            {teamMembers.map((member) => (
              <li key={member.id} className={style.member}>
                <div className={style.member__avatar}>
                  <img width="240" height="312" src={member.avatar} alt="avatar" />
                </div>
                <h3 className={style.member__name}>
                  <span className="inner">{member.name}</span>
                </h3>
                <div className={style.member__position}>
                  <ReturnHTMLParagraph htmlString={member.position} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </section>
);
