import React from 'react';
import style from './companies.module.scss';

export const Companies = () => (
  <section id="companies" className={style.companies}>
    <div className="section">
      <h2 className={`${style.title} h3`}>
        <span className="inner">Trusted by:</span>
      </h2>
      <div className={style.row}>
        <div className={style.company}>
          <img
            width="114"
            height="21"
            className={style.alludo}
            src={`${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/alludo.svg`}
            alt="alludo"
          />
        </div>
        <div className={style.company}>
          <img
            width="167"
            height="25"
            className={style.mind_manager}
            src={`${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/mind-manager.svg`}
            alt="mind manager"
          />
        </div>
        <div className={style.company}>
          <img
            width="141"
            height="20"
            className={style.corelDraw}
            src={`${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/corel.svg`}
            alt="corelDraw"
          />
        </div>
        <div className={style.company}>
          <img
            width="92"
            height="30"
            className={style.gravit}
            src={`${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/gravit.svg`}
            alt="gravit"
          />
        </div>
        <div className={style.company}>
          <img
            width="31"
            height="31"
            className={style.tq}
            src={`${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/tq.svg`}
            alt="tq"
          />
        </div>
        <div className={style.company}>
          <img
            width="111"
            height="31"
            className={style.winzip}
            src={`${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/winzip.svg`}
            alt="winzip"
          />
        </div>
        <div className={style.company}>
          <img
            width="139"
            height="30"
            className={style.reviversoft}
            src={`${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/reviversoft.svg`}
            alt="reviversoft"
          />
        </div>
      </div>
    </div>
  </section>
);
