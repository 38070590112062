import React, { useState } from 'react';
import { useDebouncedResize } from '@/hooks/useDebouncedResize';
import { getZoomLevel } from '@/utils/zoom.helpers';
import { Main } from '@/components/Main';
// import { Footer } from '@/components/Footer';
import { Banner } from '@/components/Banner';

export const Home = () => {
  /* ---- Start Font-size set that allow to disable zooming with using em in css styles ---- */
  const [fontSize, setFontSize] = useState((1 * 100) / getZoomLevel());
  const resizeHandler = () => {
    setFontSize((1 * 100) / getZoomLevel());
  };
  useDebouncedResize(resizeHandler, 100);
  /* ---- End Font-size set that allow to disable zooming with using em in css styles ---- */

  return (
    <div className="page" style={{ fontSize: fontSize }}>
      <Banner />
      <Main />
      {/* <Footer /> */}
    </div>
  );
};
