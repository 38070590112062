import React from 'react';
import style from './request.module.scss';
import { LinkButton } from '@/components/LinkButton';
import { NavMenuId } from '@/models/enums';

export const Request = () => (
  <section id="request" className={style.request}>
    <div className={`${style.section} section`}>
      <div className={style.row}>
        <div className={style.column__left}>
          <h2 className={style.title__left}>
            <span className="inner">Let’s discuss your request</span>
          </h2>
          <p className={style.text__left}>
            <span className="inner">
              We will contact you to discuss your request and give advice.
            </span>
          </p>
          <br />
          <p className={style.text__left} style={{ marginTop: '17em' }}>
            <span className="inner">Please expect a reply within 24 hours.</span>
          </p>
        </div>
        <div className={style.column__right}>
          <h3 className={style.title__right}>
            <span className="inner">What services you are interested in?</span>
          </h3>
          <div className={style.buttons}>
            <div className={style.button}>
              <p className="bold small">
                {' '}
                <span className="inner">Mobile Development</span>
              </p>
            </div>
            <div className={style.button}>
              <p className="bold small">
                {' '}
                <span className="inner">Web Development</span>
              </p>
            </div>
            <div className={style.button__light}>
              <p className="bold small">
                {' '}
                <span className="inner">Desktop Development</span>
              </p>
            </div>
            <div className={style.button__light}>
              <p className="bold small">
                {' '}
                <span className="inner">Solution Architecture</span>
              </p>
            </div>
            <div className={style.button}>
              <p className="bold small">
                {' '}
                <span className="inner">Business Analysis</span>
              </p>
            </div>
            <div className={style.button}>
              <p className="bold small">
                {' '}
                <span className="inner">Other</span>
              </p>
            </div>
          </div>
          <LinkButton
            title="Continue"
            link={NavMenuId.Contacts}
            isArrow={false}
            className="btn-request"
          />
        </div>
      </div>
    </div>
  </section>
);
