import { DataItemInterface } from '@/models/interfaces';

const hubApiUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_HUBSPOT_PORTAL_ID}/${process.env.REACT_APP_HUBSPOT_FORM_ID}`;

interface FieldInterface {
  objectTypeId: '0-1' | null;
  name: 'firstname' | 'email' | 'message' | null;
  value: string | null;
}

interface FieldsInterface {
  fields: FieldInterface[];
  context: {
    pageUri: string;
    pageName: string;
  };
}

const requestBody: FieldsInterface = {
  fields: [
    {
      objectTypeId: '0-1',
      name: 'firstname',
      value: '',
    },
    {
      objectTypeId: '0-1',
      name: 'email',
      value: '',
    },
    {
      objectTypeId: '0-1',
      name: 'message',
      value: '',
    },
  ],
  context: {
    pageUri: 'www.devpulse.io',
    pageName: 'devPulse',
  },
};
export const HubspotRequestHandler = (data: DataItemInterface[]) => {
  const prepeareRequestBody = () => {
    const prepearedBody = requestBody.fields.map((field) => {
      // Match the dataItem by name
      const dataItem = data.find((dataItem) => dataItem.name === field.name);
      if (dataItem) {
        return { ...field, value: dataItem.value };
      }
      // Return the original field if no match is found
      return field;
    });

    return { ...requestBody, fields: prepearedBody };
  };

  const sendRequest = (prepearedRequestBody: FieldsInterface) => {
    // console.log(prepearedRequestBody);
    return fetch(hubApiUrl, {
      method: 'POST',
      body: JSON.stringify(prepearedRequestBody),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  const init = () => {
    const prepearedRequestBody = prepeareRequestBody();
    return sendRequest(prepearedRequestBody);
  };

  return init();
};
