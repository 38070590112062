export enum NavMenuId {
  HoWeAre = 'howeare',
  Us = 'us',
  Services = 'services',
  Portfolio = 'portfolio',
  HowWeWork = 'howwework',
  OurTeam = 'ourteam',
  Blog = 'blog',
  Contacts = 'contacts',
}

export enum PagePath {
  Home = '/',
  PrivacyPolicy = '/privacy-policy',
}

export enum FormSubmitInputTypeEnum {
  Text = 'text',
  Email = 'email',
  Button = 'button',
  Checkbox = 'checkbox',
  Textarea = 'textarea',
}

export enum FormSubmitInputNameEnum {
  Name = 'firstname',
  Email = 'email',
  Message = 'message',
}

export enum FormSubmitInputLabelEnum {
  Name = 'Name',
  Email = 'Email',
  Message = 'Message',
}

export enum FormSubmitInputAutoCompleteEnum {
  Name = 'name',
  Email = 'email',
}
