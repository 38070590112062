import React, { FC } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';
import './button.scss';
import { NavMenuId } from '@/models/enums';

interface ButtonPropsInterface {
  title: string;
  link: NavMenuId;
  className?: string;
  isArrow?: boolean;
}

export const LinkButton: FC<ButtonPropsInterface> = ({
  title,
  link,
  className,
  isArrow = true,
}) => {
  const navigate = useNavigate();

  if (className === undefined) {
    className = 'btn';
  } else {
    className = 'btn ' + className;
  }

  const goToPageAndScroll = () => {
    navigate('/');
    setTimeout(() => {
      scroller.scrollTo(link, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }, 250);
  };
  return (
    <RouterLink to="/" onClick={goToPageAndScroll}>
      <div className={className}>
        <span>{title}</span>
        {isArrow && (
          <img
            className="img"
            src={`${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/arrow.svg`}
            alt="->"
          />
        )}
      </div>
    </RouterLink>
  );
};
