import React from 'react';
import style from './Main.module.scss';
import { Howeare } from '@/components/Howeare';
import { Companies } from '@/components/Companies';
import { Us } from '@/components/Us';
import { Request } from '@/components/Request';
import { Services } from '@/components/Services';
import { Portfolio } from '@/components/Portfolio';
import { Howwework } from '@/components/Howwework';
import { Ourteam } from '@/components/Ourteam';
import { Contacts } from '@/components/Contacts';

export const Main = () => (
  <main className={style.main}>
    <Howeare />
    <Companies />
    <Us />
    <Request />
    <Services />
    <Howwework />
    <Portfolio />
    <Ourteam />
    <Contacts />
  </main>
);
