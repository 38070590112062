import React, { FC } from 'react';
import styles from './Button.module.scss';

interface ButtonPropsInterface {
  children: string;
  buttonType: 'submit' | 'reset' | 'button';
  theme?: string[];
  disabled?: boolean;
}

export const Button: FC<ButtonPropsInterface> = ({ children, buttonType, theme, disabled }) => {
  let buttonClassNames = [styles.button];
  if (theme) {
    buttonClassNames = buttonClassNames.concat(theme);
  }

  return (
    <button type={buttonType} className={buttonClassNames.join(' ')} disabled={disabled}>
      <span>{children}</span>
    </button>
  );
};
