import React, { useState } from 'react';
import './us.scss';
import style from './us.module.scss';
import { LinkButton } from '@/components/LinkButton';
import { Advantages } from './Advantages';
import { Slider } from './Slider';
import { NavMenuId } from '@/models/enums';

export const Us = () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const selectSlideIndexHandler = (slideIndex: number) => {
    setCurrentSlideIndex(slideIndex);
  };

  return (
    <section id={NavMenuId.Us} className="whytochooseus">
      <div className={style.background__1}></div>
      <div className={style.background__2}></div>
      <div className={style.background__3}></div>
      <div className={style.background__4}></div>
      <div className={style.background__5}></div>
      <div className={`${style.section} section`}>
        <h2 className={style.title}>
          <span className="inner">
            Why <span className="color__orange">choose us</span>
          </span>
        </h2>
        <div className={style.content}>
          <div className="row">
            <div className="column column--left">
              <div className="column__inner">
                <Advantages onToggleSlider={selectSlideIndexHandler} />
                <div className={style.whytochooseus__linkButton}>
                  <LinkButton title="Contact us" link={NavMenuId.Contacts} />
                </div>
              </div>
            </div>
            <div className="column column--right">
              <div className="column__inner">
                <Slider
                  onToggleSlider={selectSlideIndexHandler}
                  currentSlideIndex={currentSlideIndex}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${style.section} section`}>
        <div className={style.values}>
          <h2 className={style.values__title}>
            <span className="inner">
              <span className="color__orange">Our values</span> are what we value.
            </span>
          </h2>
          <p className={style.values__text}>
            <span className="inner">
              DevPulse holds 5 core values that serve as{' '}
              <span className="bold">fundamental principles guiding our actions and behavior</span>.
              These values are deeply ingrained and reflect our unwavering commitment, even during
              challenging times. They shape how we interact with one another, how we conduct
              ourselves, and how we present DevPulse as a cohesive team.
              <br />
              <span className="bold">Our values are non-negotiable</span>.
              <br />
              They are the essence of what sets DevPulse apart and makes us truly exceptional.
            </span>
          </p>
          <div className={style.circles}>
            <div className={style.circle__respect}>
              <h3>
                <span className="inner">Respect</span>
              </h3>
            </div>
            <div className={style.circle__honesty}>
              <h3>
                <span className="inner">Honesty</span>
              </h3>
            </div>
            <div className={style.circle__commitmen}>
              <h3>
                <span className="inner">Commitment</span>
              </h3>
            </div>
            <div className={style.circle__mindedness}>
              <h3>
                <span className="inner">Open-mindedness</span>
              </h3>
            </div>
            <div className={style.circle__innovation}>
              <h3>
                <span className="inner">Innovation</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
