import { useCallback, useEffect } from 'react';

/**
 * Custom hook for debouncing window resize events.
 *
 * @param callback Function to be called after debounce time when resize event occurs.
 * @param delay The debounce delay in milliseconds.
 */
export const useDebouncedResize = (callback: () => void, delay: number): void => {
  const debouncedCallback = useCallback(() => {
    let timer: NodeJS.Timeout;
    return () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        callback();
      }, delay);
    };
  }, [callback, delay]);

  useEffect(() => {
    const debounced = debouncedCallback();
    window.addEventListener('resize', debounced);

    return () => window.removeEventListener('resize', debounced);
  }, [debouncedCallback]);
};
