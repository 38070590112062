import React, { ChangeEvent, FormEvent, useState } from 'react';
import styles from './FormSubmit.module.scss';
import {
  FormSubmitInputAutoCompleteEnum,
  FormSubmitInputLabelEnum,
  FormSubmitInputNameEnum,
  FormSubmitInputTypeEnum,
} from '@/models/enums';
import { DataItemInterface } from '@/models/interfaces';
import { Button } from './Button';
import { Input } from './Input';
import { HubspotRequestHandler } from './HubspotRequestHandler';
import { Spinner } from '@/components/FormSubmit/Spinner';

const initialFormData: DataItemInterface[] = [
  {
    type: FormSubmitInputTypeEnum.Text,
    name: FormSubmitInputNameEnum.Name,
    label: FormSubmitInputLabelEnum.Name,
    value: '',
    InputPlaceholder: 'Name',
    autoComplete: FormSubmitInputAutoCompleteEnum.Name,
    isErrorValidation: false,
    errorMessage: null,
  },
  {
    type: FormSubmitInputTypeEnum.Text,
    name: FormSubmitInputNameEnum.Email,
    label: FormSubmitInputLabelEnum.Email,
    value: '',
    InputPlaceholder: 'Email',
    autoComplete: FormSubmitInputAutoCompleteEnum.Email,
    isErrorValidation: false,
    errorMessage: null,
  },
  {
    type: FormSubmitInputTypeEnum.Textarea,
    name: FormSubmitInputNameEnum.Message,
    label: FormSubmitInputLabelEnum.Message,
    value: '',
    InputPlaceholder: 'Message',
    isErrorValidation: false,
    errorMessage: null,
  },
];

export const FormSubmit = () => {
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmited, setIsSubmitted] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [message, setMessage] = useState('');
  const [isShowMessage, setIsShowMessage] = useState(false);

  const showMessage = () => {
    setIsShowMessage(true);
    setTimeout(hideMessage, 5000);
  };

  const hideMessage = () => {
    setIsShowMessage(false);
    setMessage('');
    setFormData(initialFormData);
  };

  const handleFormChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    // Create a new array with the updated data
    const updatedFormData = formData.map((dataItem) => {
      if (dataItem.name === name) {
        const validatedValue = validateFormData(dataItem, value);
        if (validatedValue) {
          return {
            ...dataItem,
            value: validatedValue.value,
            isErrorValidation: validatedValue.isErrorValidation,
            errorMessage: validatedValue.errorMessage,
          }; // Update the value property for the matching item
        }
      }
      return dataItem; // Return unchanged items
    });

    // Update the state with the new data
    setFormData(updatedFormData);
  };

  const ValidateName = (name: string) => {
    // Remove spaces and replace with underscores
    const sanitizedValue = name.replace(/\s/g, '_');
    if (sanitizedValue.length >= 2 && sanitizedValue.length <= 20) {
      return {
        value: sanitizedValue,
        isErrorValidation: false,
        errorMessage: null,
      };
    }
    return {
      value: sanitizedValue,
      isErrorValidation: true,
      errorMessage: 'Name must be at least 2 characters and must be no more than 20 characters.',
    };
  };

  const ValidateEmail = (email: string) => {
    // Regular expression for Email validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isValidEmail = emailPattern.test(email);

    if (isValidEmail) {
      return {
        value: email,
        isErrorValidation: false,
        errorMessage: null,
      };
    } else {
      return {
        value: email,
        isErrorValidation: true,
        errorMessage: 'Please enter a valid Email address.',
      };
    }
  };

  const ValidateMessage = (message: string) => {
    // Remove HTML tags using a regular expression
    const sanitizedMessage = message.replace(/<[^>]*>/g, '');
    if (sanitizedMessage.length >= 10 && sanitizedMessage.length <= 50) {
      return {
        value: sanitizedMessage,
        isErrorValidation: false,
        errorMessage: null,
      };
    } else {
      return {
        value: sanitizedMessage,
        isErrorValidation: true,
        errorMessage:
          'Message must be at least 10 characters and must be no more than 50 characters.',
      };
    }
  };

  const ValidateDefault = (value: string) => {
    // Regular expression for Email validation
    const sanitizedValue = value.replace(/<[^>]*>/g, '');
    return {
      value: sanitizedValue,
      isErrorValidation: true,
      errorMessage: 'Value has not been validated.',
    };
  };

  const validateFormData = (inputDataItem: DataItemInterface, value: string) => {
    switch (inputDataItem.name) {
      case FormSubmitInputNameEnum.Name:
        return ValidateName(value);
      case FormSubmitInputNameEnum.Email:
        return ValidateEmail(value);
      case FormSubmitInputNameEnum.Message:
        return ValidateMessage(value);
      default:
        return ValidateDefault(value);
    }
  };

  const checkIsTheErrorsInTheForm = () => {
    let isErrors = false;
    formData.map((input) => {
      if (input.isErrorValidation) {
        isErrors = true;
      }
    });
    return isErrors;
  };

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitted(true);
    const isErrorsInTheForm = checkIsTheErrorsInTheForm();
    if (!isErrorsInTheForm) {
      setShowSpinner(true);
      try {
        const response = await HubspotRequestHandler(formData);
        if (response) {
          if (response.ok) {
            setMessage('Message has been sent. Thank you!');
            setShowSpinner(false);
            showMessage();
            // console.log('Request was successful: ', response);
          } else {
            throw new Error(
              'Response with unsuccessful requeste, responce status: ',
              response.status
            );
          }
        } else {
          throw new Error('Request failed. There is not response');
        }
      } catch (error) {
        setMessage('Something went wrong, please try again');
        setShowSpinner(false);
        showMessage();
        // Handle any errors that occur during the request
        console.error('Error in handleFormSubmit:', error);
      }
    }
  };

  const classNames = {
    formsubmit: () => {
      const classes = [styles.formsubmit, 'formsubmit'];
      return classes.join(' ');
    },
    group: (isError: boolean) => {
      const classes = [styles.formsubmit__group, 'form-group'];
      if (isError) classes.push(styles['formsubmit__group--error']);
      return classes.join(' ');
    },
    errorMessage: (isError: boolean) => {
      const classes = [styles.formsubmit__error];
      classes.push('small');
      if (isError) classes.push(styles['formsubmit__error--error']);
      if (isSubmited) classes.push(styles['formsubmit__error--submited']);
      return classes.join(' ');
    },
  };

  return (
    <form
      id="formsubmit"
      className={classNames.formsubmit()}
      name="formsubmit"
      onSubmit={handleFormSubmit}
    >
      {formData.map((input) => (
        <div className={classNames.group(input.isErrorValidation)} key={input.name}>
          <Input
            InputType={input.type}
            InputId={input.name}
            InputName={input.name}
            // lableTitle={input.label}
            InputPlaceholder={input.InputPlaceholder}
            InputValue={input.value}
            handleChange={handleFormChange}
            InputaAutoComplete={input.autoComplete}
            theme="formsubmit__form-control"
          />
          <p className={classNames.errorMessage(input.isErrorValidation)}>
            <span className="inner">
              <span className={styles.error__text}>{input.errorMessage}</span>
            </span>
          </p>
        </div>
      ))}
      <div className={styles.formsubmit__group}>
        <Button buttonType="submit" theme={['formsubmit__button', 'btn', 'btn-custom', 'btn-lg']}>
          Send Message
        </Button>
      </div>
      {showSpinner && <Spinner />}
      {isShowMessage && (
        <div className={styles.message__wrapper}>
          <h3 className={styles.message}>
            <span className="inner">{message}</span>
            <button
              className={styles.message__button}
              onClick={hideMessage}
              onTouchStart={hideMessage}
            >
              <span>ok</span>
            </button>
          </h3>
        </div>
      )}
    </form>
  );
};
