import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PagePath } from '@/models/enums';
import { Home, PrivacyPolicy } from '@/pages';

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path={PagePath.Home} element={<Home />} />
      <Route path={PagePath.PrivacyPolicy} element={<PrivacyPolicy />} />
    </Routes>
  </BrowserRouter>
);

export default App;
