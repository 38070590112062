export const logZoomLevel = (): void => {
  const zoomLevel: number = window.devicePixelRatio * 100;
  console.log(`Zoom level: ${zoomLevel}%`);
};

export const getZoomLevel = (): number => {
  return window.devicePixelRatio * 100;
};

export const changeContent = (level: string | number): void => {
  document.body.style.transform = `scale(${level})`;
  document.body.style.transformOrigin = '1 1';
  console.log(`Content scaled : ${level}%`);
};
