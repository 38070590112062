import React from 'react';
import { NavMenuId } from '@/models/enums';
import style from './howeare.module.scss';

interface DataEnumerationsInterface {
  id: string;
  title: string;
  desc?: string;
}

const technologies: DataEnumerationsInterface[] = [
  {
    id: 'machine_learning',
    title: 'Strong expertisein machine learning',
  },
  {
    id: 'analytics',
    title: 'InStore Analytics',
  },
  {
    id: 'optimization',
    title: 'Procurement Optimization',
  },
  {
    id: 'frontend',
    title: 'Cross-browser frontend development',
  },
  {
    id: 'testing',
    title: 'Functional / Regression testing',
  },
  {
    id: 'optimization_2',
    title: 'Procurement Optimization',
  },
];
const businessStatistic: DataEnumerationsInterface[] = [
  {
    id: 'in_business',
    title: '10+',
    desc: 'Years in business',
  },
  {
    id: 'specialists',
    title: '46',
    desc: 'Rockstar specialists',
  },
  {
    id: 'projects',
    title: '25+',
    desc: 'Completed projects',
  },
  {
    id: 'solutions',
    title: '7',
    desc: 'Completed enterprise solutions',
  },
  {
    id: 'feedbacks',
    title: '100%',
    desc: 'Positive feedbacks',
  },
];

export const Howeare = () => (
  <section id={NavMenuId.HoWeAre} className={style.howeare}>
    <div className={style.background__1}></div>
    <div className={style.background__2}></div>
    <div className={style.background__3}></div>
    <div className={`${style.section} section`}>
      <h2 className={style.title}>
        <span className="inner">
          Who <span className="color__orange">we are</span>
        </span>
      </h2>
      <div className={style.content}>
        <div className={style.rowOne}>
          <div className={style.columnLeft}>
            <h3>
              <span className="inner">
                A team of specialists, focused on Data Science and innovative technologies around
                mobile and web development.
              </span>
            </h3>
            <p>
              <span className="inner">
                Welcome to our software development company, where innovation is at the heart of
                everything we do. With our extensive experience in software engineering, we
                specialize in delivering innovative and scalable solutions that cater to the unique
                needs of each client.
              </span>
            </p>
            <p>
              <span className="inner">
                Our focus on innovation, quality, and customer satisfaction sets us apart from the
                competition, and we are dedicated to delivering outstanding results every time. At
                our core, we believe that technology can be a powerful tool for driving progress and
                transforming businesses, and we are committed to leveraging our expertise to make a
                positive impact on the world.
              </span>
            </p>
          </div>
          <div className={style.columnRight}>
            <ul className="list">
              {technologies.map((technology) => (
                <li key={technology.id} className={style.rowOneItem}>
                  <i className={style.check}></i>
                  <p className="bold">
                    <span className="inner">{technology.title}</span>
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={style.rowTwo}>
          <ul className={style.list}>
            {businessStatistic.map((statisticItem) => (
              <li key={statisticItem.id} className={style.item__inner}>
                <h1 className={style.item__title}>
                  <span className="inner">{statisticItem.title}</span>
                </h1>
                <p className={style.item__desc}>
                  <span className="inner">{statisticItem.desc}</span>
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </section>
);
