import React, { FC } from 'react';
import styles from './Input.module.scss';
import {
  FormSubmitInputAutoCompleteEnum,
  FormSubmitInputLabelEnum,
  FormSubmitInputNameEnum,
  FormSubmitInputTypeEnum,
} from '@/models/enums';

export interface InputChangeHandlerInterface {
  (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
}

interface InputPropsInterface {
  InputType: FormSubmitInputTypeEnum;
  InputId: string;
  InputName: FormSubmitInputNameEnum;
  InputPlaceholder: string;
  InputValue: string;
  handleChange: InputChangeHandlerInterface;
  InputaAutoComplete?: FormSubmitInputAutoCompleteEnum;
  theme?: string;
  textareaRows?: number;
  lableTitle?: FormSubmitInputLabelEnum;
}

export const Input: FC<InputPropsInterface> = ({
  InputType,
  InputId,
  InputName,
  InputPlaceholder,
  InputValue,
  handleChange,
  InputaAutoComplete,
  theme,
  lableTitle,
}) => {
  const InputClassNames = [styles.input, theme].join(' ');
  const textareaClassNames = [styles.textarea, theme].join(' ');

  return (
    <>
      {lableTitle && (
        <p className="formsubmit__name-block">
          {lableTitle && (
            <label htmlFor={InputName} className={styles.label}>
              <span className="inner">{lableTitle}</span>
            </label>
          )}
        </p>
      )}
      {InputType === FormSubmitInputTypeEnum.Textarea ? (
        <textarea
          id={InputId}
          name={InputName}
          placeholder={InputPlaceholder}
          value={InputValue}
          onChange={handleChange}
          rows={6}
          className={textareaClassNames}
        ></textarea>
      ) : (
        <input
          type={InputType}
          id={InputId}
          name={InputName}
          placeholder={InputPlaceholder}
          value={InputValue}
          onChange={handleChange}
          autoComplete={InputaAutoComplete}
          className={InputClassNames}
          required={true}
        />
      )}
    </>
  );
};
