import React, { MouseEvent, FC } from 'react';
import styles from './Advantages.module.scss';

interface AdvantageItemInterface {
  id: string;
  icon: string;
  title: string;
  desc: string;
  slideIndex: number;
}

interface AdvantagesPropsInterface {
  onToggleSlider: (slideIndex: number) => void;
}

const advantages: AdvantageItemInterface[] = [
  {
    id: 'tech',
    icon: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/advanteges/ico-tech.svg`,
    title: 'Personalized approach',
    desc: 'Tailoring solutions, products, or services to meet the unique requirements of each individual, rather than applying a one-size-fits-all approach.',
    slideIndex: 0,
  },
  {
    id: 'personal',
    icon: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/advanteges/ico-personal.svg`,
    title: 'Modern Technologies',
    desc: 'We are using most advanced tools, systems, and solutions that have been developed to address the current needs and demands.',
    slideIndex: 1,
  },
  {
    id: 'complex',
    icon: `${process.env.REACT_APP_CDN_ASSETS_URL}/assets/svg/advanteges/ico-complex.svg`,
    title: 'Complex Solutions',
    desc: 'Artificial intelligence, blockchain, cloud computing, Internet of Things (IoT), and 5G networks, among others.',
    slideIndex: 2,
  },
];

export const Advantages: FC<AdvantagesPropsInterface> = ({ onToggleSlider }) => {
  const toggleSliderHandler = (event: MouseEvent<HTMLElement>) => {
    const chosenSlide = event.currentTarget.getAttribute('data-slide-index');
    onToggleSlider(parseInt(chosenSlide || '0'));
  };

  return (
    <ul className={styles.advantages}>
      {advantages.map((advantage) => (
        <li
          key={advantage.id}
          className={styles.advantage}
          onClick={toggleSliderHandler}
          data-slide-index={advantage.slideIndex}
        >
          <img
            width="48"
            height="48"
            className={styles.advantage__icon}
            src={advantage.icon}
            alt={advantage.id}
          />
          <h3 className={styles.advantage__title}>
            <span className="inner">{advantage.title}</span>
          </h3>
          <p className={styles.advantage__desc}>
            <span className="inner">{advantage.desc}</span>
          </p>
        </li>
      ))}
    </ul>
  );
};
